import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';
import form from 'jquery-form';
export default {
  data: function data() {
    var _this = this;

    return {
      columns: [{
        title: '设备别名',
        dataIndex: 'equipmentName',
        width: 120,
        scopedSlots: {
          customRender: '设备别名'
        },
        ellipsis: true,
        customRender: function customRender(equipmentName) {
          var name = '';

          _this.equipmentAllList.map(function (equip) {
            if (equip.numbering === equipmentName) {
              if (equip.typeId == 3) {
                name = equip.parentEquipmentName + "-" + equip.name;
              } else {
                name = equip.name;
              }
            }
          });

          return name;
        }
      }, {
        title: '触发时间',
        dataIndex: 'startTime',
        width: 120,
        scopedSlots: {
          customRender: '触发时间'
        },
        ellipsis: true
      }, {
        title: '告警名称',
        dataIndex: 'name',
        width: 100,
        scopedSlots: {
          customRender: '告警名称'
        },
        ellipsis: true
      }, {
        title: '告警等级',
        dataIndex: 'alarmGrade',
        width: 80,
        scopedSlots: {
          customRender: '告警等级'
        },
        customRender: function customRender(alarmGrade) {
          switch (alarmGrade) {
            case 3:
              return '紧急告警';

            case 2:
              return '重要告警';

            case 1:
              return '一般告警';

            case 0:
              return '关机提示';

            default:
              return '未知';
          }
        }
      }, {
        title: '设备类型',
        dataIndex: 'triggerValue',
        width: 80,
        scopedSlots: {
          customRender: '设备类型'
        },
        customRender: function customRender(triggerValue) {
          switch (triggerValue) {
            case 6:
              return '并网点电表';

            case 5:
              return '关口电表';

            case 4:
              return '计量电表';

            case 3:
              return 'eBlock';

            case 2:
              return '能量链';

            case 1:
              return '电站';

            default:
              return '未知';
          }
        }
      }, {
        title: '告警类型',
        dataIndex: 'type',
        width: 120,
        scopedSlots: {
          customRender: '告警类型'
        },
        ellipsis: true,
        customRender: function customRender(type) {
          switch (type) {
            case 0:
              return 'eBlock告警';

            case 1:
              return 'PCS子告警';

            case 2:
              return '电池系统子告警';

            case 3:
              return '空调子告警';

            case 4:
              return 'eLink告警';

            case -1:
              return '电表通讯故障告警';

            case -2:
              return 'eBlock关机原因';

            case -3:
              return 'eBlock通讯故障';

            default:
              return '未知';
          }
        }
      }, {
        title: '结束时间',
        dataIndex: 'endTime',
        width: 120,
        scopedSlots: {
          customRender: '结束时间'
        },
        ellipsis: true
      }, {
        title: '确认时间',
        dataIndex: 'confirmTime',
        width: 120,
        scopedSlots: {
          customRender: '确认时间'
        },
        ellipsis: true
      }, {
        title: '确认人',
        dataIndex: 'confirmer',
        width: 80,
        scopedSlots: {
          customRender: '确认人'
        }
      }, {
        title: '确认备注',
        width: 100,
        dataIndex: 'confirmRemark',
        scopedSlots: {
          customRender: '确认备注'
        },
        ellipsis: true
      }],
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      dateFormat: 'YYYY-MM-DD',
      scroll: {
        y: 660
      },
      datePickerLocale: datePickerLocale,
      greadList: [{
        id: 1,
        name: '一般告警'
      }, {
        id: 2,
        name: '重要告警'
      }, {
        id: 3,
        name: '紧急告警'
      }],
      equipTypeList: [],
      equipmentList: [],
      equipmentAllList: [],
      search: {
        stationId: localStorage.getItem('stationId'),
        startTime: moment(),
        endTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        equipmentId: '-1',
        grade: '-1',
        typeId: '-1',
        length: 10000000000,
        page: 1,
        startIndex: 0
      },
      tableData3: [],
      total: 0,
      showTotal: true,
      loading: false
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.getEquipTypeList();
    this_.getEquipmentList();
    this_.getList();
  },
  methods: {
    select: function select(value) {
      var this_ = this;
      this_.getEquipmentList();
    },
    getEquipTypeList: function getEquipTypeList() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/type/list'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipTypeList = d.data.data;
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getEquipmentList: function getEquipmentList() {
      var this_ = this;
      var data = {
        typeId: this_.search.typeId,
        stationId: localStorage.getItem('stationId')
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/search_condition',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentList = d.data.data;
          this_.equipmentList.sort(this_.sortNum);

          if (this_.search.typeId == -1) {
            this_.equipmentAllList = this_.equipmentList;
          }
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    sortNum: function sortNum(a, b) {
      if (a.typeId == 3 && b.typeId == 3) {
        return (a.num - b.num) * 1;
      } else if (a.typeId == 3) {
        return -1;
      } else {
        return a.typeId - b.typeId;
      }
    },
    //获取刷新列表数据
    getList: function getList() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId,
        equipmentId: this.search.equipmentId,
        length: this.search.length,
        page: this.search.page,
        startIndex: this.search.startIndex,
        endTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        grade: this.search.grade,
        equipType: this.search.typeId
      };
      this.equipmentAllList.map(function (equip) {
        if (data.equipmentId === equip.id) {
          data.equipType = equip.typeId;
        }
      });

      if (this.search.startTime != null && this.search.startTime.toDate().getTime() > new Date().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      }

      var dataTime = '';

      if (this_.search.startTime != null) {
        dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
        data.startTime = dataTime;
      } else {
        data.startTime = '1970-01-01 00:00:00';
      }

      data.startIndex = (data.page - 1) * data.length;
      this_.loading = true;
      axios({
        method: 'post',
        url: '/emind/alarm/history/search',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.total = d.data.recordsTotal;
        this_.tableData3 = d.data.data;

        for (var i = 0; i < this_.tableData3.length; i++) {
          this_.tableData3[i].key = i;
        }

        this_.loading = false;
      }).catch(function (error) {
        this_.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.getList();
    },
    exportExcel: function exportExcel(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var name = arguments.length > 2 ? arguments[2] : undefined;
      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: url,
          // 请求地址
          data: options,
          // 参数
          responseType: 'blob' // 表明返回服务器返回的数据类型

        }).then(function (response) {
          resolve(response.data);
          var blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          var fileName = name;

          if (response.headers['content-disposition'].endsWith('.xls"')) {
            fileName = fileName + '.xls';
          } else if (response.headers['content-disposition'].endsWith('.zip"')) {
            fileName = fileName + '.zip';
          }

          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); //释放内存

            window.URL.revokeObjectURL(link.href);
          }
        }, function (err) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      });
    },
    exportFn: function exportFn() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId,
        equipmentId: this.search.equipmentId,
        length: this.search.length,
        page: this.search.page,
        startIndex: this.search.startIndex,
        endTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        grade: this.search.grade,
        typeId: this.search.typeId
      };

      if (this.search.startTime != null && this.search.startTime.toDate().getTime() > new Date().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      }

      var dataTime = '';

      if (this_.search.startTime != null) {
        dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
        data.startTime = dataTime;
      } else {
        data.startTime = '1970-01-01 00:00:00';
      }

      var now = new Date();
      var url = '/emind/alarm/history/export/' + now.getTime();
      this_.exportExcel(url, data, '实时告警-' + now.Format("yyyy-MM-dd_hh-mm-ss"));
    }
  }
};